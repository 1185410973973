import { performRequest } from './api-handler'
import { URLS } from '../utility/urls'

export default () => {
    const { GET_TOUR_PACKAGES, GET_ROOM_TYPES, GET_ROOM_NUMBER, 
            GET_ADDITIONAL_PACKAGES, ADD_RESERVATIONS, GET_BOOKING_DETAILS,
            GET_ADDONS_CATEGORY,GET_CUSTOM_ADDONS, GET_LOCATION_START_POINTS, 
            GET_LOCATION_END_POINTS, GET_BOOKING_DATA,
            UN_APPROVED_RESERVATIONS, GET_BOOKING_SUMMARY,
            UPDATE_APPROVED_RESERVATION,
            BLOCK_ROOM_TEMP, GET_INCLUDED_ADDONS, ADD_OFFLINE_PAYMENT,
            UPDATE_RESERVATIONS,
            UPDATE_AGENT_RESERVATIONS,
            GET_BOOKING_JSON, GET_BOOKING_SETTING_DETAILS,
            GET_EXCLUDED_BOAT,
            ADD_TRANSPORTATION_NOTES,
            GET_PACKAGE_BY_ID,
            USER_UPDATE_RESERVATIONS,
            GET_TYPEOFPAX_SETTING_DETAILS,
            EDIT_BOOKING_JSON,
            ROOM_TIMER,
            SELECT_ROOM_AUTOMATICALLY,
            EXTEND_ROOM_TIME,
            UPDATE_UN_APPROVE_INCLUDED_TRANSPORTATIONS,
            UPDATE_APPROVE_INCLUDED_TRANSPORTATIONS,
            GET_APPROVED_JSON,
            GET_UN_APPROVED_JSON,
            GET_PAYMENT_DETAILS,
            GET_WALLET,
            GET_PACKAGES_DISCOUNT,
            GET_PROMOCODE_BY_CODE,
            PAY_FOR_BOOKING_WALLET,
            ADD_APPROVED_BOOKING_OFFLINE_PAYMENT,
            UNAPPROVEDBOOKING_ADD_TRANSPORTATION_NOTES,
            GET_CHECKEDIN_BOOKINGS,
            ROOMS_AVAILABILITY,
            UN_BLOCK_ROOM_TEMP
        } = URLS
    return {
        payBookingFromWallet: (body={}, method='POST') =>
            performRequest(method ? method:'POST', PAY_FOR_BOOKING_WALLET, body, "agent"),
        getPrmoCodeByCode: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', GET_PROMOCODE_BY_CODE.replace(":code", body.code), body, "admin"),
        getPackageDiscount: (body={}, method='POST',id) =>
            performRequest(method ? method:'POST', GET_PACKAGES_DISCOUNT.replace(":_id", id), body, "admin"),
        unApprovedUpdateIncludedTransportation:(body = {}, method = 'put') =>
            performRequest(method ? method : 'put', UPDATE_UN_APPROVE_INCLUDED_TRANSPORTATIONS.replace(":_id", body._id), body, "agent"),
        approvedUpdateIncludedTransportation:(body = {}, method = 'put') =>
            performRequest(method ? method : 'put', UPDATE_APPROVE_INCLUDED_TRANSPORTATIONS.replace(":_id", body._id), body, "agent"),
        getBookingDetails: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', GET_TOUR_PACKAGES, body, "agent"),
        getPackageInfo: (body={}, method='get',id) =>
            performRequest(method ? method:'get', GET_PACKAGE_BY_ID.replace(":_id", id), body),   
        getRoomTypes: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', GET_ROOM_TYPES, body),
        getRoomNumbers: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', GET_ROOM_NUMBER, body),
        getAdditionalPackages:(body = {}, method = 'post') =>
            performRequest(method ? method : 'post', GET_ADDITIONAL_PACKAGES, body),
        postReservations:(body = {}, method = 'post') =>
            performRequest(method ? method : 'post', ADD_RESERVATIONS, body, "agent"),
        editApprovedReservations:(body = {}, method = 'put', id) =>
            performRequest(method ? method : 'put', UPDATE_APPROVED_RESERVATION.replace(":_id", id), body, "agent"),
        getBookingDetailsByRoomWise:(body = {}, method = 'post') =>
            performRequest(method ? method : 'post', GET_BOOKING_DETAILS, body),
        getAddOnsCategory:(body = {}, method = 'post') =>
            performRequest(method ? method : 'post', GET_ADDONS_CATEGORY, body),
        getCustomAddons:(body = {}, method = 'get') =>
            performRequest(method ? method : 'get', GET_CUSTOM_ADDONS, body, "agent"),
        getBookingListing:(body = {}, method = 'get',queryParam) =>
            performRequest(method ? method : 'get', `${GET_BOOKING_DATA}${queryParam}`, body, "agent"),
        getUnApprovedBookings:(body = {}, method = 'get',queryParam) =>
            performRequest(method ? method : 'get', `${UN_APPROVED_RESERVATIONS}${queryParam}`, body, "agent"),
        getLocationStartPoints:(body = {}, method = 'get') =>
            performRequest(method ? method : 'get', GET_LOCATION_START_POINTS, body),
        getLocationEndPoints:(body = {}, method = 'get') =>
            performRequest(method ? method : 'get', 
                GET_LOCATION_END_POINTS.replace(":_id", body._id).replace(":type", body.type), body),
        getBookingSummary:(body = {}, method = 'get') =>
            performRequest(method ? method : 'get', 
            UPDATE_AGENT_RESERVATIONS.replace(":_id", body._id), body, "agent"),
        blockRoomTemp:(body = {}, method = 'post') =>
            performRequest(method ? method : 'post', 
            BLOCK_ROOM_TEMP.replace(":_id", body._id), body),
        getIncludedAddOns:(body = {}, method = 'post') =>
            performRequest(method ? method : 'post', GET_INCLUDED_ADDONS, body),
        saveOfflinePayment:(body = {}, method = 'post') =>
            performRequest(method ? method : 'post', 
                ADD_OFFLINE_PAYMENT.replace(":_id", body._id), body, "agent"),
        saveOfflinePaymentForApprovedBooking:(body = {}, method = 'post') =>
            performRequest(method ? method : 'post', 
                ADD_APPROVED_BOOKING_OFFLINE_PAYMENT.replace(":_id", body._id), body, "agent"),
        updateReservations:(body = {}, method = 'put') =>
            performRequest(method ? method : 'put', 
            USER_UPDATE_RESERVATIONS.replace(":_id", body._id), body),
        getApprovedPostjson:(body = {}, method = 'get') =>
            performRequest(method ? method : 'get', 
                GET_APPROVED_JSON.replace(":_id", body._id), body, "agent"),
        getUnApprovedPostjson:(body = {}, method = 'get') =>
            performRequest(method ? method : 'get', 
                GET_UN_APPROVED_JSON.replace(":_id", body._id), body, "agent"),
        getLeadSources:(body = {}, method = 'get') =>
            performRequest(method ? method : 'get', 
                GET_BOOKING_SETTING_DETAILS, body),
        getExcludedBoats:(body = {}, method = 'get') =>
            performRequest(method ? method : 'get', 
                GET_EXCLUDED_BOAT, body),
        getPaxDetails:(body = {}, method = 'get') =>
            performRequest(method ? method : 'get', 
                GET_TYPEOFPAX_SETTING_DETAILS, body),
        addTransportationNotes:(body = {}, method = 'put') =>
            performRequest(method ? method : 'put', 
                ADD_TRANSPORTATION_NOTES.replace(":_id", body._id).replace(":transId", body.transId), body, 'agent'),
        removeTransportationNotes:(body = {}, method = 'delete') =>
            performRequest(method ? method : 'delete', 
                ADD_TRANSPORTATION_NOTES.replace(":_id", body._id).replace(":transId", body.transId), body, 'agent'),
        editBookingJson:(body = {}, method = 'put') =>
            performRequest(method ? method : 'put', 
                EDIT_BOOKING_JSON.replace(":_id", body._id), body, "agent"),
        roomTimer:(body = {}, method = 'get') =>
            performRequest(method ? method : 'get', 
                ROOM_TIMER, body),
        getAutomaticallySelectedRoom:(body = {}, method = 'post') =>
            performRequest(method ? method : 'post', SELECT_ROOM_AUTOMATICALLY, body, "agent"),
        timeExtension:(body = {}, method = 'post') =>
            performRequest(method ? method : 'post', 
                EXTEND_ROOM_TIME, body, "agent"),
        getPaymentDetails:(body = {}, method = 'get') =>
            performRequest(method ? method : 'get', GET_PAYMENT_DETAILS, body, "agent"),
        getWalletDetails:(body = {}, method = 'get') =>
            performRequest(method ? method : 'get', GET_WALLET, body, "agent"),
        UnapprovedBookingAddTransportationNotes:(body = {}, method = 'put') =>
            performRequest(method ? method : 'put', 
                UNAPPROVEDBOOKING_ADD_TRANSPORTATION_NOTES.replace(":_id", body._id).replace(":transId", body.transId), body, 'agent'),
        UnapprovedBookingRemoveTransportationNotes:(body = {}, method = 'delete') =>
            performRequest(method ? method : 'delete', 
                UNAPPROVEDBOOKING_ADD_TRANSPORTATION_NOTES.replace(":_id", body._id).replace(":transId", body.transId), body, 'agent'),
        getCheckedInBookings: (body = {}, method = 'get') => 
            performRequest(method ? method : 'get', 
                    GET_CHECKEDIN_BOOKINGS, body, 'agent'),  
        getRoomsAvailability: (body={}, method='post',) =>
            performRequest(method ? method:'post', ROOMS_AVAILABILITY, body),
        unBlockRoomTemp:(body = {}, method = 'delete') =>
            performRequest(method ? method : 'delete', 
                UN_BLOCK_ROOM_TEMP.replace(":_id", body._id), body),
    }
}