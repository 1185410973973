import demoLogo from "./logo/logo.png";
import summerbaylogo from "../assets/img/logo/tcb-logo.png"
import { portalData } from "./portalData";

let  logo = demoLogo

let applicationName = "Travoxios Digital Intelligence Platform (TDIP)"

let siteName = portalData?.travoxisWebsite

let FooterName = "Travoxis Technology SDN BHD"

if(process.env.REACT_APP_PRODUCTION_MODE !== "STAGE"){
    applicationName = "Travoxis Digital Intelligence Platform (SBDIP)"
    logo = summerbaylogo
    FooterName = "Travoxis Technology SDN BHD"
}

export {
    logo,
    applicationName,
    siteName,
    FooterName
}
